import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import {
    Button,
    Layout,
    Carousel,
    Form, Input, Divider, Image, Modal
} from 'antd';

import moment from "moment";

import { translateListData } from 'store/actions/translate'


const { Content } = Layout;

/*new*/
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import map_img from "../../../assets/images/map.png"

import {
    GalleryArrow, LeftArrow, NewsArrow, NewsNextArrow, NewsPrevArrow,
    QuotaIcon, RightArrow
} from "assets/images/icons/Icons";
import {getPostByCategoryId, getPostById, getPostsByType, InsertPost, PostData, saveForm} from "../../services/Post";
import {useActions} from "hooks/useActions";

const HomePage:React.FC<any> = ({translate}) => {
    const { translateListData } = useActions()
    const [visible, setVisible] = useState<boolean>(false);
    const [clickedImage, setClickedImage] = useState<number>(0);
    const nav1=useRef(null);
    const nav2=useRef(null);
    const newsSlider=useRef(null);
    const [form] = Form.useForm();

    const [sliders, setSliders] = useState<PostData[]>();
    const [aboutUs, setAboutUs] = useState<PostData>();
    const [service, setService] = useState<PostData>();
    const [services, setServices] = useState<PostData[]>();
    const [growData, setGrowData] = useState<PostData>();
    const [testimonials, setTestimonials] = useState<PostData[]>();
    const [gallery, setGallery] = useState<any[]>();
    const [news, setNews] = useState<PostData[]>();
    const [partners, setPartners] = useState<PostData[]>();

    const getPosts = async () => {
        const get_sliders = await getPostsByType(7);

        setSliders(old_data => get_sliders.data);

        /**/
        const get_about = await getPostById(2);

        setAboutUs(old_data => get_about.data);

        /**/
        const get_service = await getPostById(3);

        setService(old_data => get_service.data);

        /**/
        const get_services= await getPostByCategoryId(3);
        setServices(old_data => get_services.data);

        /**/
        const get_grow = await getPostById(7);
        setGrowData(old_data => get_grow.data);

        /**/
        const get_testimonial=await getPostsByType(12);
        setTestimonials(old_data => get_testimonial.data);

        /**/
        const get_gallery = await getPostById(11);
        setGallery(old_dat => Object.values(get_gallery.data?.metas?.gallery));

        /**/
        const get_news=await getPostsByType(13);
        setNews(old_data => get_news.data);

        /**/
        const get_partners=await getPostsByType(14);
        setPartners(old_data => get_partners.data);

    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {


        getPosts();

    }, [translate])

    const onFinish = async (values: any) => {
        let form_data=new FormData<InsertPost>();
        let metas={
            1 : values.phone,
            2 : values.email,
            3 : values.country,
        }

        form_data.append('title', values.name);
        form_data.append('content', values.message ?? '');
        form_data.append('meta', JSON.stringify(metas));

        const insert=await saveForm(15, form_data);

        form.resetFields();

        Modal.success({
            content: translate?.c_f_success,
        });
    };

    return (
        <>
            <section className={'full home_slider_section'} >

                <Carousel
                    dots={true}
                    arrows={false}
                    swipe={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    className={'home_top_slider'}
                    swipeToSlide={true}
                    autoplay={true}
                    autoplaySpeed={5000}
                    pauseOnHover={true}
                    infinite={sliders?.length > 1}
                >

                    {
                        sliders?.map(row => {
                            let div_class=(Object.values(row.metas.buttons).length === 1) ? 'align_center' : '';

                            return (
                                <div className={'home_top_slider_item full'} >
                                    <img src={row.featured} alt={row.title} title={row.title} />

                                    <div className={'full full_h home_slider_section_in'} >
                                        <div className={`center full_h flex direction_column  justify_center ${div_class}`} >
                                            <div className={`home_banner_in flex  direction_column justify_center ${div_class}`} >
                                                <h4>{row.excerpt}</h4>
                                                <h1>{row.title}</h1>
                                                <div dangerouslySetInnerHTML={{__html : row.content}} />

                                                <div className={'flex align_center btn_list'}>
                                                    {
                                                        Object.values(row?.metas?.buttons)?.map((btn, count) => {
                                                            let curr_btn=btn[1];
                                                            let btn_class=(count > 0) ? 'red_btn2' : '';

                                                            return (
                                                                <a href={curr_btn?.key} target={"_blank"}  className={`ant-btn btn ${btn_class}`} >
                                                                    {curr_btn?.value}
                                                                </a>
                                                            )
                                                        })
                                                    }

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                    {/*<div className={'home_top_slider_item full'} >
                        <img src={home_banner} />

                        <div className={'full full_h home_slider_section_in'} >
                            <div className={'center full_h flex direction_column  justify_center'} >
                                <div className={'home_banner_in flex  direction_column  '} >
                                    <h4>BS GROUP</h4>
                                    <h1>BRIDGE TO SUCCESS</h1>
                                    <p>
                                        We are a close tean of professionals who work together to provide <br/>
                                        the effiecnt networking and matchmaking solutions
                                    </p>

                                    <div className={'flex align_center btn_list'}>
                                        <Button type={'ghost'} className={'btn red_btn2'} >
                                            Contact us
                                        </Button>

                                        <Button type={'ghost'} className={'btn'} >
                                            OUR EVENTS
                                        </Button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={'home_top_slider_item full'} >
                        <img src={home_banner} />

                        <div className={'full full_h home_slider_section_in'} >
                            <div className={'center full_h flex direction_column  justify_center'} >
                                <div className={'home_banner_in flex  direction_column  '} >
                                    <h4>BS GROUP</h4>
                                    <h1>UPCOMING EVENT</h1>
                                    <p>
                                        We are a close tean of professionals who work together to provide <br/>
                                        the effiecnt networking and matchmaking solutions
                                    </p>

                                    <div className={'flex align_center btn_list'}>
                                        <Button type={'ghost'} className={'btn red_btn2'} >
                                            Visit Website
                                        </Button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>*/}



                </Carousel>



            </section>

            <section id={'about'} className={'full about_submit_section'} >
                <div className={'center'} >
                    <div className={'full section_title_left about_submit_section_in'} >
                        <h2>{aboutUs?.title}</h2>

                        <div dangerouslySetInnerHTML={{__html: aboutUs?.content}} />
                    </div>
                </div>
            </section>

            <section className={'full section_three_box'} >
                <div className={'center'} >
                    <div className={'full section_three_box_in flex direction_column'} >
                        <h2 dangerouslySetInnerHTML={{__html: service?.excerpt}} />

                        <ul className={'full flex justify_between section_three_service_list'} >
                            {
                                services?.map(row => {
                                    return (
                                        <li>
                                            <div className={'three_box_icon flex align_center justify_center '} >
                                                <img src={row.featured} alt={row.title} title={row.title} />
                                            </div>
                                            <h4>{row.title}</h4>
                                            <div dangerouslySetInnerHTML={{__html: row.content}} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>

            <section className={'full grow_section'} >
                <img src={growData?.featured} alt={growData?.title} title={growData?.title} className={'pos_abs full full_h'} />

                <div className={'center full_h'} >
                    <div className={'full flex direction_column full_h justify_center grow_section_in'} >
                        <h2 dangerouslySetInnerHTML={{__html: growData?.title}} />

                        <div dangerouslySetInnerHTML={{__html: growData?.content}} />

                    </div>
                </div>
            </section>

            <section className={'full about_submit_section testimonials_section'} >
                <div className={'center'} >
                    <div className={'full flex direction_column section_title_left about_submit_section_in'} >
                        <h2>{translate?.testimonials}</h2>

                        <div className={'center'} >
                            <div className={'full testimonials_section_slider_box'} >
                                <Carousel
                                    dots={false}
                                    arrows={false}
                                    swipe={true}
                                    slidesToShow={1}
                                    slidesToScroll={1}
                                    asNavFor={nav2.current}
                                    ref={nav1}
                                    infinite={testimonials?.length > 1}
                                >
                                    {
                                        testimonials?.map(row => {
                                            return (
                                                <div className={'testimonial_carousel_item'} >
                                                    <div className={'flex direction_column testimonial_carousel_left'} >
                                                        <h6>{row.title}</h6>

                                                        <h3>{row.excerpt}</h3>

                                                        <h4>{row.metas.position}</h4>
                                                        <h5>{row.metas.company}</h5>

                                                        <div className={'flex direction_column testimonial_text'} >
                                                            <QuotaIcon />

                                                            <div dangerouslySetInnerHTML={{__html: row.content}} />
                                                        </div>
                                                    </div>



                                                    <div className={'flex direction_column testimonial_carousel_right'} >
                                                        <iframe
                                                            className={'full full_h'}
                                                            width="100%"
                                                            height="100%"
                                                            src={`https://www.youtube-nocookie.com/embed/${row.metas.yt_link}`}
                                                            title="YouTube video player" frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </Carousel>
                            </div>

                            <div className={'full flex justify_between testimonials_section_slider_bottom'} >
                                <a  href={'https://www.youtube.com/channel/UCSsvr8DxCHCIIDA7WCCzb7A'} target={"_blank"}  className={'btn btn_black all_interviews'} >{translate?.all_interviews}</a>

                                <div className={'testimon_thumbnails'} >
                                    <Carousel
                                        slidesToShow={3}
                                        slidesToScroll={1}
                                        arrows={true}
                                        dots={false}
                                        prevArrow={<Button type={"ghost"} icon={<LeftArrow />}  /> }
                                        nextArrow={<Button type={"ghost"} icon={<RightArrow />} /> }
                                        asNavFor={nav1.current}
                                        ref={nav2}
                                        infinite={testimonials?.length > 1}
                                    >
                                        {
                                            testimonials?.map((row, index) => {
                                                return (
                                                    <Button type={"ghost"} className={'ghost_btn testimonial_user_img'} onClick={() => {nav1.current.goTo(parseInt(index));} } >
                                                        <img src={row.featured} alt={row.title} title={row.title} />
                                                    </Button>
                                                )
                                            })
                                        }
                                    </Carousel>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className={'full gallery_section'} >

                <Carousel
                    dots={false}
                    arrows={true}
                    swipe={true}
                    slidesToShow={6}
                    slidesToScroll={1}
                    prevArrow={<></>}
                    nextArrow={<Button type={"ghost"} className={'gallery_btn gallery_next_btn  flex align_center justify_center'} icon={<GalleryArrow />} />}
                    className={'gallery_slider'}
                    rows={2}
                    infinite={gallery?.length > 6}
                >

                    {
                        gallery?.map((img, index) => {
                            return (
                                <Image
                                    preview={{ visible: false }}
                                    width={200}
                                    src={img}
                                    onClick={() => {
                                        setClickedImage(old_img => index);
                                        setVisible(true)
                                    }}
                                />
                            )
                        })
                    }

                </Carousel>

                <div style={{ display: 'none' }}>
                    <Image.PreviewGroup
                        preview={{
                            visible,
                            onVisibleChange: vis => setVisible(vis),
                            current: clickedImage
                        }}

                    >

                        {
                            gallery?.map((img, index) => {
                                return (
                                    <Image
                                        width={200}
                                        src={img}
                                    />
                                )
                            })
                        }
                    </Image.PreviewGroup>
                </div>

            </section>

            <section className={'full home_news_section'} >
                <div className={'center'} >
                    <div className={'full flex direction_column section_title_left about_submit_section_in home_news_section_in'} >
                        <div className={'full flex justify_between align_center home_news_section_top'} >
                            <h2>{translate?.news}</h2>

                            <div className={'flex align_center'} >
                                {/*<Button onClick={() => { newsSlider.current.prev(); }} type={"ghost"} className={'news_slider_arrow news_slider_prev_arrow'} icon={<NewsPrevArrow />} />*/}
                                <Button onClick={() => { newsSlider.current.next(); }} type={"ghost"} className={'news_slider_arrow news_slider_next_arrow'} icon={<NewsNextArrow />} />
                            </div>
                        </div>

                        <div className={'full news_slider_box'} >
                            <Carousel
                                dots={false}
                                arrows={false}
                                swipe={true}
                                slidesToShow={3}
                                slidesToScroll={1}
                                className={'news_slider'}
                                ref={newsSlider}
                                infinite={news?.length > 3}
                            >

                                {
                                    news?.map(row => {
                                        let news_date=moment(row.date).format("DD-MMMM-YYYY");

                                        return (
                                            <a href={row.metas.link} target={"_blank"} className={'news_item flex direction_column'} >
                                                <div className={'full news_item_img'} >
                                                    <img src={row.featured} alt={row.title} title={row.title} className={'full full_h'} />
                                                </div>

                                                <span className={'news_date'} >{news_date}</span>
                                                <h4>{row.title}</h4>
                                            </a>
                                        )
                                    })
                                }

                            </Carousel>
                        </div>


                    </div>
                </div>
            </section>

            <section className={'full map_section '} style={{display: "none"}} >
                <div className={'center'} >
                    <div className={'full map_section_in flex justify_between align_center'}>

                        <ul className={'flex map_left_list'} >
                            <li>
                                <strong>3</strong>
                                <p>Countries</p>
                            </li>

                            <li>
                                <strong>3</strong>
                                <p>Countries</p>
                            </li>

                            <li>
                                <strong>3</strong>
                                <p>Countries</p>
                            </li>

                            <li>
                                <strong>3</strong>
                                <p>Countries</p>
                            </li>
                        </ul>

                        <img src={map_img} />

                    </div>
                </div>
            </section>

            <section id={'partners'} className={'full about_submit_section partners_section'} >
                <div className={'center'} >
                    <div className={'full section_title_left about_submit_section_in partners_section_in'} >
                        <h2>{translate?.partners}</h2>

                        <Carousel
                            dots={false}
                            arrows={true}
                            swipe={true}
                            slidesToShow={6}
                            slidesToScroll={1}
                            prevArrow={<Button icon={<LeftArrow />} type={"ghost"} className={'partner_arrow partner_arrow_left'} />}
                            nextArrow={<Button icon={<RightArrow />} type={"ghost"} className={'partner_arrow partner_arrow_right'} />}
                            className={'partner_slider'}
                            infinite={partners?.length > 6}
                        >

                            {
                                partners?.map(row => {
                                    return (
                                        <a href={row.metas.link} target={'_blank'}>
                                            <img src={row.featured} alt={row.title} title={row.title} />
                                        </a>
                                    )
                                })
                            }

                        </Carousel>
                    </div>
                </div>
            </section>

            <section id={'contact'} className={'full contact_section'} >
                <div className={'center'}>
                    <div className={'full flex direction_column '}>
                        <h2>{translate?.contact_us}</h2>
                        <p dangerouslySetInnerHTML={{__html: translate?.contact_us_desc}} />

                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            className={'flex justify_center'}
                            onFinish={onFinish}
                        >
                            <ul className={'full contact_list'} >
                                <li>
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input  />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="phone"
                                        label="Phone"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input type={'tel'} />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="email"
                                        label="Email Address"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input type={'email'} />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="country"
                                        label="Country"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input type={"text"}  />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="message"
                                        label="Message (optional)"
                                    >
                                        <Input.TextArea  />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Button type="primary" htmlType="submit" className={'btn btn_black submit_btn'} >
                                        SEND MESSAGE
                                    </Button>
                                </li>
                            </ul>
                        </Form>


                    </div>
                </div>
            </section>

        </>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => {
    return {
        translate:  state.translate.translateData?.result
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
