import React , {useEffect} from 'react'
import {
  Layout
} from 'antd';
const { Content } = Layout;
import { Route, Switch, useLocation } from 'react-router-dom'
import {connect} from "react-redux";


/**/
import HomePage from 'components/Home/index'
import { translateListData } from 'store/actions/translate'
import ScrollToTop from './ScrollToTop';
import HeaderComponent from "components/Template/Header";
import FooterComponent from "components/Template/Footer";
import ServicesComponent from "components/pages/Services";
import EventsComponent from "components/pages/Events";
import VirtualConferences from "components/pages/VirtualConferences";
import VirtualConferenceIn from "components/posts/VirtualConferences";
import CareerComponent from "components/pages/Career";


const routes = [
    {
        component: HomePage,
        exact: true,
        path: '/',
    },
    {
        component: ServicesComponent,
        exact: true,
        path: '/services',
    },
    {
        component: EventsComponent,
        exact: true,
        path: '/events',
    },
    {
        component: VirtualConferences,
        exact: true,
        path: '/virtual_conferences',
    },
    {
        component: VirtualConferenceIn,
        exact: true,
        path: '/virtual_conference/:slug',
    },
    {
        component: CareerComponent,
        exact: true,
        path: '/career',
    },
]

import PreloaderLogo from "../../assets/images/preloader_logo.png";

const Routes:React.FC<any> = ({translate}) =>  {
  const location = useLocation();
  const pathname=location.pathname;

  // @ts-ignore
  return (
      <>

          <div className={'preloader'}>
              <img src={PreloaderLogo} className={'preloader_logo'}/>

              <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
              </div>
          </div>

          <HeaderComponent />

          <main className={'main full '} role={'main'}  >

              <ScrollToTop />

              <Switch>
                  {
                      routes.map((route:any) => (
                          <Route {...route} key={route.path}/>
                      ))

                  }
              </Switch>

          </main>

          <FooterComponent />
      </>

  )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => ({
    translate:  state.translate.translateData.result
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
