import React, {useEffect, useLayoutEffect, useState} from "react";
import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import {Link, NavLink, useLocation} from "react-router-dom";
import {Button, Divider, Form, Input, Modal} from "antd";
import logo from "assets/images/logo.png";
import {FbIcon, LnIcon, TwIcon, YtIcon} from "assets/images/icons/Icons";
import {getMenusByType, MenuData} from "../../services/Menu";
import {useActions} from "hooks/useActions";
import {InsertPost, saveForm} from "../../services/Post";

const FooterComponent:React.FC<any> = ({translate}) => {
    const { translateListData } = useActions()
    const location = useLocation();
    const pathname=location.pathname;
    const [form] = Form.useForm();

    const footer_class=pathname==='/' ? 'is_home_page' : ''

    const [menus, setMenus] = useState<MenuData[]>()
    const [menus2, setMenus2] = useState<MenuData[]>()

    const getMenus = async () => {
        const menus=await getMenusByType(2);

        setMenus(old_data => menus.data)

        /**/
        const menus2=await getMenusByType(3);

        setMenus2(old_data => menus2.data)
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getMenus();

    }, [translate])

    const onFinish = async (values: any) => {
        let form_data=new FormData<InsertPost>();

        form_data.append('title', values.email);

        const insert=await saveForm(16, form_data);

        form.resetFields();

        Modal.success({
            content: translate?.sf_success,
        });
    };

    return (
        <footer className={`footer full ${footer_class}`} >
            <div className={'center center2'} >
                <div className={'full flex direction_column'} >
                    <div className={'footer_top justify_center full'} >
                        <nav>
                            <ul className={'header_menus flex footer_top_menus'} >
                                {
                                    menus?.map(row => {

                                        return (
                                            <li><NavLink to={`/${row.content}`} activeClassName={'active'} exact={true} >{row.title}</NavLink></li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>

                    <Divider className={'footer_divider'} />

                    <div className={'footer_middle full flex justify_between'} >

                        <div className={'flex direction_column footer_middle_box footer_middle_box_1'}>

                            <Link to={'/'} className={'footer_logo'} >
                                <img src={logo} alt={''} />
                            </Link>

                            <p>{translate?.company_desc}</p>

                            <ul className={'flex header_soc_icons footer_soc_icons'} >
                                <li>
                                    <a href={'https://twitter.com/BSGroup_'} target={'_blank'} >
                                        <TwIcon />
                                    </a>
                                </li>

                                <li>
                                    <a href={'https://www.facebook.com/bsgroupuk/'} target={'_blank'} >
                                        <FbIcon />
                                    </a>
                                </li>

                                <li>
                                    <a href={'https://www.linkedin.com/company/bs-g/'} target={'_blank'} >
                                        <LnIcon />
                                    </a>
                                </li>

                                <li>
                                    <a href={'https://www.youtube.com/channel/UCSsvr8DxCHCIIDA7WCCzb7A'} target={'_blank'} >
                                        <YtIcon />
                                    </a>
                                </li>
                            </ul>

                        </div>


                        <div className={'flex direction_column footer_middle_box footer_middle_box_2'}>
                            <h3>OUR EVENTS</h3>

                            <ul className={'flex direction_column footer_middle_menus'} >
                                {
                                    menus2?.map(row => {

                                        return (
                                            <li><a href={`${row.content}`} target={"_blank"} >{row.title}</a></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>


                        <div className={'flex direction_column footer_middle_box footer_middle_box_3'}>
                            <h3>CONTACTS</h3>

                            <ul className={'flex direction_column footer_middle_menus'} >
                                <li><p>Adress: {translate?.addr_az}</p></li>
                                <li><p>Phone: <a href={`tel:${translate?.tel_az}`}>{translate?.tel_az}</a></p></li>
                                <li><p>Email: <a href={'mailto:info@bsgroup.uk'}>info@bsgroup.uk</a></p></li>
                                <li><a href={'www.bsgroup.uk'}>www.bsgroup.uk</a></li>
                            </ul>

                            <Divider className={'footer_divider footer_divider2'}/>

                            <ul className={'flex direction_column footer_middle_menus footer_middle_menus2'} >
                                <li><p>Adress: {translate?.adr_pl}</p></li>
                                <li><p>Phone: <a href={`tel:${translate?.header_tel}`}>{translate?.header_tel}</a></p></li>
                                <li><p>Email: <a href={`mailto:${translate?.header_email}`}>{translate?.header_email}</a></p></li>
                            </ul>
                        </div>


                        <div className={'flex direction_column footer_middle_box footer_middle_box_3'}>
                            <h3>NEWS</h3>

                            <Form
                                className={'flex direction_column subscribe_form'}
                                form={form}
                                autoComplete="off"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="email"
                                    label=""
                                    rules={[{ required: true, message: translate?.req_msg }]}
                                >
                                    <Input type={'email'} placeholder={'Your Email'} />
                                </Form.Item>

                                <Button className={'btn'} htmlType={'submit'} >
                                    SUBSCRIBE
                                </Button>
                            </Form>
                        </div>


                    </div>

                    <Divider className={'footer_divider'} />

                    <div className={'full footer_bottom'} >
                        <p>
                            © {new Date().getFullYear()} BS Group. All rights reserved. Privacy Policy and Cookies agreement
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => {
    return {
        translate:  state.translate.translateData?.result
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterComponent)
