import React, {useEffect, useLayoutEffect, useState} from "react";
import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import PageHeader from "components/Template/PageHeader";
import {getPostByCategoryId, getPostById, PostData} from "../../services/Post";
import {useActions} from "hooks/useActions";

const ServicesComponent:React.FC<any> = ({translate}) => {
    const { translateListData } = useActions()
    const [service, setService] = useState<PostData>();
    const [services, setServices] = useState<PostData[]>();

    const getPosts = async () => {
        const get_service = await getPostById(3);

        setService(old_data => get_service.data);

        /**/
        const get_services= await getPostByCategoryId(6);
        setServices(old_data => get_services.data);
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getPosts();

    }, [translate])

    return (
        <>

            <PageHeader id={3} />


            <section className={'full about_submit_section about_submit_section2'} >
                <div className={'center'} >
                    <div className={'full section_title_left about_submit_section_in'} dangerouslySetInnerHTML={{__html: service?.content}} />
                </div>
            </section>

            <section className={'full section_three_box section_three_box2'} >
                <div className={'center'} >
                    <div className={'full section_three_box_in flex direction_column'} >
                        <h2 dangerouslySetInnerHTML={{__html: service?.excerpt}} />

                        <ul className={'full flex justify_between section_three_service_list section_three_service_list2'} >

                            {
                                services?.map(row => {
                                    return (
                                        <li>
                                            <div className={'three_box_icon flex align_center justify_center '} >
                                                <img src={row.featured} alt={row.title} title={row.title} />
                                            </div>
                                            <h4>{row.title}</h4>
                                            <div dangerouslySetInnerHTML={{__html: row.content}} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>

        </>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => {
    return {
        translate:  state.translate.translateData?.result
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesComponent)
