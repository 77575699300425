import React, {useEffect, useLayoutEffect, useState} from "react";
import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import {
    EventStatusIcon
} from "assets/images/icons/Icons";
import PageHeader from "components/Template/PageHeader";
import {getPostsByType, PostData} from "../../services/Post";
import {useActions} from "hooks/useActions";

const EventsComponent:React.FC<any> = ({translate}) => {
    const { translateListData } = useActions()
    const [posts, setPosts] = useState<PostData[]>();

    const getPosts = async () => {
        const get_posts= await getPostsByType(8);
        setPosts(old_data => get_posts.data);
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getPosts();

    }, [translate])

    return (
        <>

            <PageHeader id={14} />


            <section className={'full events_section'} >
                <div className={'center'} >
                    <div className={'full events_section_in'} >
                        <ul className={'full flex direction_column event_list'} >
                            {
                                posts?.map(row => {
                                    let category=row?.categories[0];

                                    return (
                                        <li>
                                            <img src={row.featured} alt={row.title} title={row.title} />
                                            <div className={'flex direction_column event_item_center'} >
                                                <h2>{row.title}</h2>

                                                <div dangerouslySetInnerHTML={{__html: row.content}} />

                                                <table className={'event_item_info_table'} >
                                                    <tr>
                                                        <td><strong>Date</strong></td>
                                                        <td>: <span>{row.metas.date}</span></td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Country</strong></td>
                                                        <td>: <span>{row.metas.country}</span></td>
                                                    </tr>
                                                </table>
                                            </div>

                                            <div className={'flex direction_column align_center justify_center event_item_right'} >

                                                <div className={'pos_rel event_status align_center justify_center flex'} >
                                                    <EventStatusIcon />
                                                    <h3>{category.title}</h3>
                                                </div>

                                                <a href={row.metas.link} target={"_blank"} className={'btn red_btn2 visit_website_link'} >VISIT WEBSITE</a>

                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>


        </>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => {
    return {
        translate:  state.translate.translateData?.result
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsComponent)
