import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Form, Input, Upload, Modal} from 'antd';


import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import career_right_img from "assets/images/career_right_img.png";
import hr_img from "assets/images/hr_img.png";

import {
    CloseIcon,
    HomePageIcon, PlusIcon, SearchIcon
} from "assets/images/icons/Icons";
import PageHeader from "components/Template/PageHeader";
import {getPostById, getPostsByType, InsertPost, PostData, saveForm} from "../../services/Post";
import {useActions} from "hooks/useActions";

const modal_data={
    title : '',
    content : '',
    excerpt : ''
}

const CareerComponent:React.FC<any> = ({translate}) => {
    const { translateListData } = useActions()
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState(modal_data);
    const [form] = Form.useForm();


    const [post, setPost] = useState<PostData>();
    const [posts, setPosts] = useState<PostData[]>();

    const getPosts = async () => {
        const get_post = await getPostById(20);

        setPost(old_data => get_post.data);

        /**/
        const get_posts= await getPostsByType(20);
        setPosts(old_data => get_posts.data);
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getPosts();

    }, [translate])

    const openModal = (row) => {

      setModalData(old_data => ({
          title:  row.title,
          content:  row.content,
          excerpt : row.excerpt
      }));

      setModalVisible(true);
    }

    const closeModal = () => {
        setModalVisible(false);

        setModalData(old_data => (modal_data));
    }

    const onFinish = async (values: any) => {
        let form_data=new FormData<InsertPost>();
        let metas={
            1 : values.meta_1,
            2 : values.meta_2,
            3 : values.meta_3,
        }

        form_data.append('title', values.title);
        form_data.append('content', values.content);
        form_data.append('meta', JSON.stringify(metas));
        form_data.append('post_file', values.post_file.fileList[0].originFileObj);

        const insert=await saveForm(24, form_data);

        form.resetFields();

        Modal.success({
            content: translate?.cf_success,
        });
    };

    return (
        <>

            <PageHeader id={20} />

            <section className={'full section_three_box career_section'} >
                <div className={'center'} >
                    <div className={'full section_three_box_in career_section_in flex justify_between align_center ' } >
                        <div className={'flex direction_column career_left'} >
                            <h2 dangerouslySetInnerHTML={{__html: post?.excerpt}} />

                            <div dangerouslySetInnerHTML={{__html:post?.content}} />
                        </div>

                        <img src={career_right_img} className={'career_right_img'}  />
                    </div>
                </div>
            </section>

            <section className={'full search_vacancy_section'} >
                <div className={'center'} >
                    <div className={'full search_vacancy_section_in flex direction_column '}>
                        <h2>Hot Vacancy</h2>
                        <p>We hire characters to work with. </p>

                        <ul className={'full flex search_vacancy_list'} >
                            {
                                posts?.map((row, index) => {
                                    return (
                                        <li>
                                            {
                                                index == 1 && <img src={hr_img} alt={row.title} title={row.title} className={'hr_img'} />
                                            }
                                            <div className={'full flex align_center vacancy_name'} >
                                                <h3>{row.title}</h3>

                                                <Button type={"ghost"} htmlType={"button"} icon={<SearchIcon />} className={'search_btn'} />
                                            </div>


                                            <Button type={"primary"} htmlType={"button"} onClick={(e) => {openModal(row)} } className={'search_vacancy_more_info_btn'} >MORE INFO</Button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>

            <section className={'full contact_section send_cv_section'} >
                <div className={'center'}>
                    <div className={'full flex direction_column '}>
                        <h2>SEND US YOUR CV</h2>
                        <p dangerouslySetInnerHTML={{__html: translate?.cv_form_desc}} />

                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            className={'flex justify_center'}
                            onFinish={onFinish}
                        >
                            <ul className={'full contact_list'} >
                                <li>
                                    <Form.Item
                                        name="title"
                                        label="Name"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input  />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="meta_1"
                                        label="Phone"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input type={'tel'} />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="meta_2"
                                        label="Email Address"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input type={'email'} />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="meta_3"
                                        label="Country"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input type={"text"}  />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="content"
                                        label="Message"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Input.TextArea  />
                                    </Form.Item>
                                </li>

                                <li>
                                    <Form.Item
                                        name="post_file"
                                        label="Attach CV"
                                        rules={[{ required: true, message: translate?.req_msg }]}
                                    >
                                        <Upload
                                            name={'post_file'}
                                            className={'upload_file'}
                                        >
                                            <Button type={"ghost"} icon={<PlusIcon />} className={'upload_file_btn'} />
                                        </Upload>
                                    </Form.Item>
                                </li>

                                <li>
                                    <Button type="primary" htmlType="submit" className={'btn btn_black submit_btn'} >
                                        SEND MESSAGE
                                    </Button>
                                </li>
                            </ul>
                        </Form>


                    </div>
                </div>
            </section>


            <Modal
                title={modalData.title}
                visible={modalVisible}
                closeIcon={<CloseIcon />}
                className={'vacancy_info_modal'}
                onCancel={closeModal}
                footer={modalData.excerpt}
                centered={true}
            >
                <div className={'flex direction_column modal_content'} dangerouslySetInnerHTML={{__html : modalData.content}} />

            </Modal>
        </>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => {
    return {
        translate:  state.translate.translateData?.result
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CareerComponent)
