import React, {useEffect, useLayoutEffect, useState} from "react";
import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import {
    EventStatusIcon,
} from "assets/images/icons/Icons";
import {Link} from "react-router-dom";
import PageHeader from "components/Template/PageHeader";
import {getPostsByType, PostData} from "../../services/Post";
import {useActions} from "hooks/useActions";

const VirtualConferencesComponent:React.FC<any> = ({translate}) => {
    const { translateListData } = useActions()

    const [posts, setPosts] = useState<PostData[]>();

    const getPosts = async () => {
        const get_posts= await getPostsByType(17, 10);
        setPosts(old_data => get_posts.data);
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getPosts();

    }, [translate])

    return (
        <>

            <PageHeader id={16} />


            <section className={'full events_section'} >
                <div className={'center'} >
                    <div className={'full events_section_in'} >
                        <ul className={'full flex direction_column event_list virtual_event_list'} >
                            {
                                posts?.map(row => {
                                    let speakers=row.child_posts[10];

                                    return (
                                        <li>
                                            <Link to={`/virtual_conference/${row.slug}`} >
                                                <img src={row.featured} alt={row.title} title={row.title} />
                                            </Link>
                                            <div className={'flex direction_column event_item_center'} >
                                                <Link to={`/virtual_conference/${row.slug}`} >
                                                    <h2>{row.title}</h2>
                                                </Link>

                                                <p dangerouslySetInnerHTML={{__html: row.excerpt}} />

                                                <table className={'event_item_info_table'} >
                                                    <tr>
                                                        <td><strong>Time</strong></td>
                                                        <td>: <span>{row.metas.time}</span></td>
                                                    </tr>

                                                    <tr>
                                                        <td><strong>Platform</strong></td>
                                                        <td>: <span>{row.metas.platform}</span></td>
                                                    </tr>
                                                </table>

                                                <div className={'flex align_center v_event_person'} >
                                                    <div className={'flex v_event_person_list'} >
                                                        {
                                                            speakers?.map(speaker => {
                                                                return (
                                                                    <img src={speaker.featured} alt={speaker.title} title={speaker.title} />
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    <span>{speakers?.length} Speakers, Live Question ....</span>
                                                </div>
                                            </div>

                                            <div className={'flex direction_column align_center justify_center event_item_right'} >

                                                <div className={'pos_rel event_status v_event_status direction_column align_center justify_center flex'} >
                                                    <EventStatusIcon />
                                                    <span>Ticket From</span>
                                                    <h3>FREE</h3>
                                                </div>

                                                <a href={row.metas.register_link} target={"_blank"} className={'btn red_btn2 visit_website_link'} >REGISTER NOW</a>

                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>


        </>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => {
    return {
        translate:  state.translate.translateData?.result
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualConferencesComponent)
