import React, {useEffect, useLayoutEffect, useState} from "react";
import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import { useScrollYPosition } from 'react-use-scroll-position';
import { EmailIcon, FbIcon, LnIcon, PhoneIcon, TwIcon, YtIcon} from "assets/images/icons/Icons";
import {Link, useLocation, NavLink} from "react-router-dom";
import logo from "assets/images/logo.png";
import {getMenusByType, MenuData} from "../../services/Menu";
import {useActions} from "hooks/useActions";
import {useHistory} from "react-router";

const HeaderComponent:React.FC<any> = ({translate}) => {
    const { translateListData } = useActions()
    const location = useLocation();
    const navigate = useHistory();
    const pathname=location.pathname;
    const scrollY = useScrollYPosition();

    let header_class=pathname==='/' ? 'is_home_page' : ''

    header_class += scrollY >= 60 ? ' is_sticky ' : '';

    const [menus, setMenus] = useState<MenuData[]>()


    const getMenus = async () => {
      const menus=await getMenusByType(1);

      setMenus(old_data => menus.data)
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getMenus();

    }, [translate])

    const scrollSection = (get_id : any) => {
        const scrollOptions = {
            left: 0,
            top: document.getElementById(get_id).offsetTop,
            behavior: 'smooth'
        }

        window.scrollTo(scrollOptions);
    }
    const menuClick = (link : string) => {
        let check_link = (link.indexOf('#') != -1) ? '/' + link : link;

        if (link.indexOf('#') != -1) {
            let get_id = link?.replace('#', '');



            if (location.pathname !== '/'){

                navigate.push({
                    pathname: '/'
                });

                setTimeout(() => {
                    scrollSection(get_id);
                }, 200)

            }else{
                scrollSection(get_id);
            }


        } else {
            navigate.push({
                pathname: check_link
            });

        }

        return false;
    }

    return (
        <header className={`full header ${header_class}`} >
            <div className={'full header_top'} >
                <div className={'center'} >
                    <div className={'full flex align_center header_top_in justify_between'} >
                        <ul className={'flex header_contact'} >
                            <li>
                                <a href={`tel:${translate?.header_tel}`}>
                                    <PhoneIcon />
                                    <span>{translate?.header_tel}</span>
                                </a>
                            </li>

                            <li>
                                <a href={`mailto:${translate?.header_email}`}>
                                    <EmailIcon />
                                    <span>{translate?.header_email}</span>
                                </a>
                            </li>
                        </ul>

                        <ul className={'flex header_soc_icons'} >
                            <li>
                                <a href={'https://twitter.com/BSGroup_'} target={'_blank'} >
                                    <TwIcon />
                                </a>
                            </li>

                            <li>
                                <a href={'https://www.facebook.com/bsgroupuk/'} target={'_blank'} >
                                    <FbIcon />
                                </a>
                            </li>

                            <li>
                                <a href={'https://www.linkedin.com/company/bs-g/'} target={'_blank'} >
                                    <LnIcon />
                                </a>
                            </li>

                            <li>
                                <a href={'https://www.youtube.com/channel/UCSsvr8DxCHCIIDA7WCCzb7A'} target={'_blank'} >
                                    <YtIcon />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={'full header_bottom '} >
                <div className={'center'} >
                    <div className={'full flex align_center justify_between header_bottom_in'} >
                        <Link to={'/'} className={'header_logo'} >
                            <img src={logo} alt={''} />
                        </Link>

                        <nav>
                            <ul className={'header_menus flex '} >
                                {
                                    menus?.map(row => {

                                        return (
                                            <li><NavLink onClick={(e) => { e.preventDefault(); menuClick(row.content) }} to={`${row.content}`}  exact={true} >{row.title}</NavLink></li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any) => {
    return {
        translate:  state.translate.translateData?.result
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
