import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import {
    CloseIcon,
    HomePageIcon, LeftArrow, RightArrow,
} from "assets/images/icons/Icons";
import {Button, Carousel, Form, Input, Modal,Select, Table} from "antd";
const { Option } = Select;

import {getChildPosts, getPostBySlug, getPostsByType, InsertPost, PostData, saveForm} from "../../services/Post";
import {Link} from "react-router-dom";
import {useActions} from "hooks/useActions";
import Fancybox from "../../services/Fancybox";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const modalVisibleReset = {
    showModal: false,
    modalName: '',
    modalCompany: '',
    modalJob: '',
    modalDesc: '',
    modalImage: '',
    modalLogo: '',
    modalSite: '',
}

const VirtualConferencesComponent:React.FC<any> = ({translate, ownProps}) => {
    const columns = [
        {
            title: translate?.speaker,
            dataIndex: 'speaker',
            width: 484,
            align: 'center',
            className: 'column'
        },
        {
            title: translate?.topic,
            dataIndex: 'topic',
            width: 422,
            align: 'center'
        },
        {
            title: translate?.time,
            dataIndex: 'time',
            width: 193,
            align: 'center'
        },
    ];

    const { translateListData } = useActions()
    const [registerModalVisible, setRegisterModalVisible] = useState(false);
    const [form] = Form.useForm();
    const post_slug=ownProps.match.params.slug;

    const [post, setPost] = useState<PostData>();
    const [sponsors, setSponsors] = useState<PostData[]>();
    const [speakers, setSpeakers] = useState<PostData[]>();
    const [prevEvents, setPrevEvents] = useState<PostData[]>();
    const [countryList, setCountryList] = useState<PostData[]>();
    const [tableItems, setTableItems] = useState<PostData[]>()
    const [modalVisible, setModalVisible] = useState(modalVisibleReset);
    const nextArrow = useRef()
    const prevArrow = useRef()

    const getPosts = async () => {
        const get_post = await getPostBySlug(post_slug);

        setPost(old_data => get_post?.data);

        /**/
        const get_sponsors = await getChildPosts(9, get_post.data.id);
        setSponsors(old_data => get_sponsors.data);

        /**/
        const get_speakers = await getChildPosts(10, get_post.data.id);
        setSpeakers(old_data => get_speakers.data);

        /**/
        const get_agenda = await getChildPosts(19, get_post.data.id);
        setTableItems(old_data => get_agenda.data);

        /**/
        const get_prev_events = await getPostsByType(21);
        setPrevEvents(old_data => get_prev_events.data);


        /**/
        const country= await getPostsByType(23);
        setCountryList(old_data => country.data)
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getPosts();


    }, [translate])

    const showModal = (item) => {

        setModalVisible(old_data => ({
            ...modalVisible,
            showModal: true,
            modalName: item.title,
            modalCompany: item.metas.country,
            modalJob: item.excerpt,
            modalDesc: item.content,
            modalImage: item.featured,
            modalLogo: item.metas.company_logo[0],
            modalSite: item.metas.site,
        }))
    }

    const openModal = () => {

        setRegisterModalVisible(true);
    }

    const closeModal = () => {
        setRegisterModalVisible(false);

    }

    function handleChange(value) {

    }

    const onFinish = async (values: any) => {
        let form_data=new FormData<InsertPost>();
        let metas={
            1 : values.company_name,
            2 : values.position,
            3 : values.phone,
            4 : values.email,
            5 : values.country,
        }

        form_data.append('title', values.full_name);
        form_data.append('parent_id', post?.id);
        form_data.append('meta', JSON.stringify(metas));

        const insert=await saveForm(18, form_data);

        form.resetFields();

        closeModal();

        Modal.success({
            content: translate?.sf_fo_success,
        });
    };

    const Table1 = tableItems?.map(item => {
        return {
            key: item.id,
            speaker: item.parent2_name,
            topic: item.metas.time,
            time: item.metas.time,
            id: item.categories[0].id == 1
        }
    })

    const Table2 = tableItems?.map(item => {
        return {
            key: item.id,
            speaker: item.parent2_name,
            topic: item.metas.time,
            time: item.metas.time,
            id: item.categories[0].id == 2
        }
    })

    return (
        <>

            <section className={'full page_header'} >
                <img src={post?.metas.banner_photo[0]} alt={post?.title} title={post?.title} className={'pos_abs full full_h'} />

                <div className={'full_h full  page_header_in'} >

                    <div className={'center full_h '} >

                        <div className={'full full_h flex direction_column justify_center'} >
                            <Link to={'/'}>
                                <HomePageIcon />
                                <span>MAIN  PAGE</span>
                            </Link>

                            <h1>{post?.title}</h1>

                            <a href={post?.metas.register_link} target={'_blank'} className={'btn register_conference_btn v_conf_register_btn'} >
                                REGISTER NOW
                            </a>
                        </div>

                    </div>


                </div>


            </section>

            <section className={'full about_submit_section virtual_conference_detail'} >
                <div className={'center'} >
                    <div className={'full section_title_left about_submit_section_in virtual_conference_detail_in '} >
                        <h2>ABOUT VIRTUAL CONFERENCE</h2>

                        <div className={'full flex direction_column virtual_conference_detail_box'} >

                            <div dangerouslySetInnerHTML={{__html: post?.content}} />

                            <ul className={'flex direction_column conference_timing'} >
                                <li><strong>Date and Time:</strong> {post?.metas.time}</li>
                                <li><strong>Duration:</strong> {post?.metas.duration}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>

            {
                post?.metas.sponsor_status==='1' && <section className={'full about_submit_section partners_section v_conference_sponsor_section'} >
                    <div className={'center'} >
                        <div className={'full section_title_left about_submit_section_in partners_section_in'} >
                            <h2>SPONSORS</h2>

                            <div className={'flex align_center justify_between v_conference_sponsor_slider_section'} >
                                <div className={'flex_1 slider_box'}>
                                    <Carousel
                                        autoplay={true}
                                        dots={false}
                                        arrows={false}
                                        swipe={true}
                                        slidesToShow={3}
                                        slidesToScroll={1}
                                        prevArrow={<Button icon={<LeftArrow />} type={"ghost"} className={'partner_arrow partner_arrow_left'} />}
                                        nextArrow={<Button icon={<RightArrow />} type={"ghost"} className={'partner_arrow partner_arrow_right'} />}
                                        className={'partner_slider'}
                                        infinite={sponsors?.length > 3}
                                    >

                                        {
                                            sponsors?.map((item, index) => {
                                                return (
                                                    <a href={item?.metas?.link} target={'_blank'} className='sponsors_item' key={index}>
                                                        <h3 className="sponsor_header">{item.title}</h3>
                                                        <div className='carousel_img' >
                                                            <img src={item.featured} />
                                                        </div>
                                                    </a>
                                                )
                                            })
                                        }

                                    </Carousel>
                                </div>

                                <Button className={'btn red_btn'} onClick={openModal} >
                                    REGISTER NOW
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {
                post?.metas.speakers_status==='1' && <section className={'full about_submit_section partners_section v_conference_speakers_section'} >
                    <div className={'center'} >
                        <div className={'full section_title_left  partners_section_in'} >
                            <h2>SPEAKERS</h2>

                            <Carousel
                                dots={true}
                                arrows={true}
                                swipe={true}
                                rows={2}
                                slidesPerRow={3}
                                prevArrow={<LeftOutlined />}
                                nextArrow={<RightOutlined />}
                                className={' conference_speakers_carousel'}
                                infinite={false}
                            >

                                {
                                    speakers?.map((item, index) => {
                                        return (
                                            <div>
                                                <div className='conference_speakers_card flex align_center' key={`speaker_${index}`}>

                                                    <div className='conference_speakers_img_container flex justify_center align_center' onClick={() => { showModal(item) }}>
                                                        <img src={item?.featured}  alt={item?.title} title={item?.title} />
                                                    </div>


                                                    <div className='conference_speakers_info flex direction_column justify_center' >
                                                        <h3 className='conference_speakers_header'>{item?.title}</h3>
                                                        <p className='conference_speakers_position'>{item?.excerpt}</p>
                                                        <p className='conference_speakers_company_name'>{item?.metas.country}</p>
                                                        <a href={item?.metas?.site} target={'_blank'} className={'conference_speakers_company_logo flex'}>
                                                            <img src={item?.metas.company_logo[0]} alt={item?.metas.country} title={item?.metas.country} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Carousel>
                        </div>
                    </div>
                </section>
            }

            {
                post?.metas.agenda_status==='1' && <section className={'full agendaSection'} >
                    <div className={'center'} >
                        <div className={'full section_title_left agendaSectionIn'} >
                            <h2>AGENDA</h2>

                            <div className="agenda_container full">

                                <Carousel
                                    className='agenda_carousel'
                                    infinite={false}
                                    arrows={true}
                                    prevArrow={<Button type={'button'} ref={prevArrow} >SESSION 1</Button>}
                                    nextArrow={<Button type={'button'} ref={nextArrow}>SESSION 2</Button>}
                                    focusOnSelect={true}
                                >

                                    <Table columns={columns} dataSource={Table1} className='agenda_table' rowClassName='agenda_table_row' bordered={true} pagination={false} />
                                    <Table columns={columns} dataSource={Table2} className='agenda_table' rowClassName='agenda_table_row' bordered={true} pagination={false} />
                                </Carousel>

                            </div>

                        </div>
                    </div>
                </section>
            }

            {
                post?.metas.prev_conf_status==='1' && <section className={'full prevEventsSection'} >
                    <div className={'center'} >
                        <div className={'full section_title_left prevEventsSectionIn'} >
                            <h2>PREVIOUS VIRTUAL EVENTS</h2>

                            <Carousel
                                dots={false}
                                arrows={false}
                                swipe={true}
                                slidesToShow={3}
                                slidesToScroll={1}
                                className={'partner_slider prevEventSlider'}
                                infinite={prevEvents?.length > 5}
                            >

                                {
                                    prevEvents?.map(row => {
                                        return (
                                            <Fancybox options={{ infinite: false }} key={row.id}>
                                                <div className='prevEventImgBox'>
                                                    <img src={row.featured}
                                                         data-fancybox="video"
                                                         data-src={row?.metas?.yt_link}
                                                         className={'full full_h'}
                                                    />
                                                </div>

                                            </Fancybox>
                                        )
                                    })
                                }

                            </Carousel>

                        </div>
                    </div>
                </section>
            }


            <Modal
                title={`REGISTER FORM`}
                visible={registerModalVisible}
                closeIcon={<CloseIcon />}
                className={'register_form_modal'}
                onCancel={closeModal}
                footer={false}
                centered={true}
            >
                <Form
                    form={form}
                    className={'flex direction_column'}
                    onFinish={onFinish}
                >

                    <ul className={'full flex direction_column register_form_list'} >
                        <li>
                            <Form.Item
                                name="company_name"
                                label=""
                                rules={[{ required: true, message: translate?.req_msg }]}

                            >
                                <Input type={"text"} placeholder={'Company Name'} />
                            </Form.Item>
                        </li>

                        <li>
                            <Form.Item
                                name="full_name"
                                label=""
                                rules={[{ required: true, message: translate?.req_msg }]}

                            >
                                <Input type={"text"} placeholder={'Full Name'} />
                            </Form.Item>
                        </li>

                        <li>
                            <Form.Item
                                name="position"
                                label=""
                                rules={[{ required: true, message: translate?.req_msg }]}

                            >
                                <Input type={"text"} placeholder={'Position'} />
                            </Form.Item>
                        </li>

                        <li>
                            <Form.Item
                                name="phone"
                                label=""
                                rules={[{ required: true, message: translate?.req_msg }]}

                            >
                                <Input type={"tel"} placeholder={'Phone Number'} />
                            </Form.Item>
                        </li>

                        <li>
                            <Form.Item
                                name="email"
                                label=""
                                rules={[{ required: true, message: translate?.req_msg }]}

                            >
                                <Input type={"email"} placeholder={'Email'} />
                            </Form.Item>
                        </li>

                        <li>
                            <Form.Item
                                name="country"
                                label=""
                                rules={[{ required: true, message: translate?.req_msg }]}

                            >
                                <Select
                                    placeholder={'Country'}
                                    onChange={handleChange}
                                >
                                    {
                                        countryList?.map(row => {
                                            return (
                                                <Option value={row.title}>{row.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </li>

                        <li>
                            <Button type="primary" htmlType="submit" className={'btn red_btn2 full'} >
                                Submit
                            </Button>
                        </li>

                    </ul>

                </Form>

            </Modal>

            <Modal
                title={'Speaker’s Bio'}
                wrapClassName="vertical-center-modal"
                visible={modalVisible.showModal}
                onOk={() => setModalVisible(modalVisibleReset)}
                onCancel={() => setModalVisible(modalVisibleReset)}
                footer={null}
                width={953}
                className="speakers_modal"
                centered={true}
            >
                <div className="speakers_modal_body flex justify_between">
                    <div>
                        <div className="speakers_modal_left flex direction_column align_center">
                            <img src={modalVisible.modalImage} className='speakers_img' />

                            <a href={modalVisible.modalSite} target={"_blank"}>
                                <img src={modalVisible.modalLogo} alt={modalVisible.modalCompany} title={modalVisible.modalCompany} className='speakers_info_logo' />
                            </a>
                        </div>
                    </div>
                    <div className="speakers_modal_right">
                        <h3 className='speakers_header'>{modalVisible.modalName}</h3>
                        <p className='speakers_job'> {modalVisible.modalCompany} | {modalVisible.modalJob}</p>
                        <div className='speakers_modal_line'></div>

                        <div className='speakers_modal_paragraph' dangerouslySetInnerHTML={{ __html: modalVisible.modalDesc }} />
                    </div>
                </div>

            </Modal>
        </>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any, ownProps : any) => {
    return {
        translate:  state.translate.translateData?.result,
        ownProps : ownProps
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualConferencesComponent)
