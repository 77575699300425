import React, {useEffect, useLayoutEffect, useState} from "react";
import {translateListData} from "store/actions/translate";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {HomePageIcon} from "assets/images/icons/Icons";
import {getPostById, PostData} from "../../services/Post";
import {useActions} from "hooks/useActions";


const PageHeader:React.FC<any> = ({translate, ownProps}) => {
    const { translateListData } = useActions()
    const [pageData, setPageData] = useState<PostData>();
    let post_id=ownProps.id;

    const getPageData = async (id) => {
      const page_data = await getPostById(id);

      setPageData(old_data => page_data.data);
    }

    useLayoutEffect(() => {
        translateListData();
    }, [])

    useEffect(() => {

        getPageData(post_id);

    }, [translate])

    return (
        <section className={'full page_header'} >
            <img src={pageData?.featured} alt={pageData?.title} title={pageData?.title} className={'pos_abs full full_h'} />

            <div className={'full_h full  page_header_in'} >

                <div className={'center full_h '} >

                    <div className={'full full_h flex direction_column justify_center'} >
                        <Link to={'/'}>
                            <HomePageIcon />
                            <span>MAIN  PAGE</span>
                        </Link>

                        <h1>{pageData?.title}</h1>
                    </div>

                </div>


            </div>


        </section>
    )
}

const mapDispatchToProps = { translateListData }

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        translate:  state.translate.translateData?.result,
        ownProps : ownProps
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
